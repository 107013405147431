<div class="container-md container-fluid">
    <div class="row">
        <div class="col-12 d-flex" style="justify-content: center; align-items: center; height: 100vh; width: 100vw;">
            <div class="col-12 form">
                <div class="col-12 d-flex" style="justify-content: center;" *ngIf="!portalName">
                    <div class="spinner-border" role="status" style="color: var(--primary-color);">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="portalName">
                    <div class="col-12 d-flex" style="justify-content: center;">
                        <img height="44px" [src]="getPrimaryLogo()" alt="">
                    </div>
                    <div class="col-12 pt-1 head d-flex" style="align-items: center; justify-content: center;">
                        Welcome to
                        <div class="ms-2 portalName" style="color: #08080A; font-weight: 500; letter-spacing: -0.48px;">
                            {{portalName}}
                        </div>
                    </div>
                    <div class="col-12 pt-5">
                        <div class="col-12 pt-2">
                            <input matInput class="form-control" name="Email" type="email" placeholder="Email"
                                formControlName="Email">
                        </div>
                        <div class="col-12 d-flex" style="justify-content: start;">
                            <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                *ngIf="isSubmitted && errorControl['Email']['errors']?.['required']">
                                Email is required
                            </mat-error>
                            <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                *ngIf="isSubmitted && form.get('Email')?.hasError('email') && (form.get('Email')?.dirty || form.get('Email')?.touched)">
                                Invalid email format
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-12 pt-3">
                        <div class="col-12 pt-2" style="position: relative;">
                            <input matInput class="form-control" name="Password"
                                [type]="showPassword ? 'password' : 'text'" placeholder="Password"
                                formControlName="Password" tyle="padding-right: 40px;">
                            <img (click)="togglePassword()" class="eye"
                                [src]="showPassword ? '../../assets/eye-icon.svg' : '../../assets/eye-slash.svg'"
                                alt="Eye Icon">
                        </div>
                        <div class="col-12 d-flex" style="justify-content: start;">
                            <mat-error class="pt-2" style="font-size: calc(var(--font-medium));"
                                *ngIf="isSubmitted && errorControl['Password']['errors']?.['required']">
                                Password is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="col-12 pt-5 d-flex" style="justify-content: end;">
                        <button type="submit" class="btn" [disabled]="isLoading">
                            <span *ngIf="!isLoading">
                                Login
                            </span>
                            <span *ngIf="isLoading">
                                <div class="spinner-border text-light" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>