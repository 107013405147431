import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInAuthGuard } from './auth/signInAuthGurd';
import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { SigninComponent } from './form/signin/signin.component';
import { AuthGuard } from './auth/authGuard';

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  // {
  //   path: 'landing-page',
  //   component: LandingPageComponent,
  //   canActivate: [SignInAuthGuard],
  // },
  {
    path: 'sign-in',
    component: SigninComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '/sign-in' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
