import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, map, catchError, of } from 'rxjs';
import { AuthService } from './authService';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    const token = localStorage.getItem('token');
    if (token) {
      return this.authService.isAuthenticated(token).pipe(
        map((authenticated: boolean) => {
          if (!authenticated) {
            // Clear localStorage when the token is not valid
            localStorage.clear();
            this.router.navigate(['sign-in']);
          }
          return authenticated;
        }),
        catchError(() => {
          // Clear localStorage on error
          localStorage.clear();
          this.router.navigate(['sign-in']);
          return of(false);
        })
      );
    } else {
      this.router.navigate(['']);
      return of(false);
    }
  }
}
