import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/authService';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrl: './landing-page.component.scss',
})
export class LandingPageComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.getportal();
  }

  navigateToRoute(route: string): void {
    this.router.navigate([route]);
  }

  getportal(): void {
    this.authService.getportal().subscribe(
      (response: any) => {
        console.log(response);
        document.documentElement.style.setProperty(
          '--primary-color',
          response.primary_color.toLowerCase()
        );
        document.documentElement.style.setProperty(
          '--secondary-color',
          response.secondary_color.toLowerCase()
        );
        document.documentElement.style.setProperty(
          '--default-logo',
          response.logo
        );
      },
      (error: any) => {
        console.log('Get Portal : ', error);
      }
    );
  }

  getPrimaryLogo() {
    const primaryLogo = getComputedStyle(document.documentElement)
      .getPropertyValue('--default-logo')
      .trim();
    return primaryLogo;
  }
}
