<div class="custom-back">
    <div class="container-md container-fluid">
        <div class="row">
            <div class="col-12 main d-flex" style="justify-content: center; align-items: center;">
                <div class="col-12 back-main">
                    <div class="col-lg-6 col-12">
                        <div class="col-12 left-side">
                            <div class="col-12">
                                <img [src]="getPrimaryLogo()" alt="">
                            </div>
                            <div class="col-12">
                                <div class="d-flex" style="justify-content: start; align-items: center;">
                                    <div class="head">
                                        Welcome
                                    </div>
                                    <div class="rectangle ms-3"></div>
                                </div>
                                <div class="head">[Client Name]</div>
                                <div class="desc pt-4 mt-md-1">
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum, fuga iste? Placeat et
                                    esse impedit excepturi iure, tempora voluptas eligendi atque cupiditate, error natus
                                    asperiores sint quaerat, doloribus dolores molestiae.
                                </div>
                                <div class="col-12 pt-4 mt-md-1">
                                    <button type="button" class="btn btn-start p-2"
                                        (click)="navigateToRoute('sign-in')">
                                        Start Your Adventure Now</button>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="sub-head d-flex">
                                    <p>1.7M+</p>
                                    <img src="../../../assets/gemini-ai-icon.png">
                                </div>
                                <div class="d-flex" style="align-items: center;">
                                    <p class="desc">Active users around <br> the world</p>
                                    <button type="button" class="btn circular-btn ms-3">
                                        <img src="../../../assets/next-arrow-icon.svg">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 d-lg-block d-none">
                        <div class="landing-img"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>